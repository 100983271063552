import 'bootstrap/dist/js/bootstrap.min.js';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css'


$('#contact-form-button').on('click', function(e) {
    e.preventDefault();
    grecaptcha.ready(function () {
        grecaptcha.execute('6Lf5hC4pAAAAAAREo0djB8NkNL5bc9iUrrBDXnG3', { action: 'submit' }).then(function (token) {

    var alertPrototype = $('#toast-alert-prototype').data('prototype');

    $('.form-error').remove();
    $('.form-control').removeClass('error');

    $.ajax({
        url: '/infomiks/send_mail.php',
        dataType: 'json',
        method: 'post',
        data: {
            'nonce': $('#contact_form_nonce').val(),
            '_nonce': $('#_contact_form').val(),
            'email': $('#contact_form_email').val(),
            'phone': $('#contact_form_phone').val(),
            'subject': $('#contact_form_subject').val(),
            'content': $('#contact_form_content').val(),
        }
    }).done(function (response) {
        if (response.success) {
            alertPrototype = alertPrototype.replace('__status_class__', 'success');
            alertPrototype = alertPrototype.replace('__icon__', '<i class="fas fa-solid fa-check check"></i>');
            alertPrototype = alertPrototype.replace('__text_1__', 'Formularz wysłany poprawnie');
            alertPrototype = alertPrototype.replace('__text_2__', 'Dziękujemy za skorzystanie z formularz kontaktowego. Skontaktujemy się z Tobą tak szybko jak to możliwe');
            $('#footer').prepend(alertPrototype);
            $('#contact_form').remove();

            setTimeout(() => {
                $('.toast-alert').removeClass('active').remove();
            }, 5000);
        }
        else {
            var errors = '';
            for(let error in response.errors) {
                errors += response.errors[error] + '<br />';
                $('#contact_form_' + error).addClass('error').after('<span class="form-error">' + response.errors[error] + '</span>');
            }

            alertPrototype = alertPrototype.replace('__status_class__', 'error');
            alertPrototype = alertPrototype.replace('__icon__', '<i class="fa-solid fa-xmark error"></i>');
            alertPrototype = alertPrototype.replace('__text_1__', 'Formularz zawiera błędy');
            alertPrototype = alertPrototype.replace('__text_2__', errors);
            $('#footer').prepend(alertPrototype);

            setTimeout(() => {
                $('.toast-alert').removeClass('active').remove();
            }, 5000);
        }
    }).fail(function () {
        alertPrototype = alertPrototype.replace('__status_class__', 'error');
        alertPrototype = alertPrototype.replace('__icon__', '<i class="fa-solid fa-xmark error"></i>');
        alertPrototype = alertPrototype.replace('__text_1__', 'Błąd wewnętrzny');
        alertPrototype = alertPrototype.replace('__text_2__', 'Coś poszlo nie tak, spróbuj jeszcze raz');
        $('#footer').prepend(alertPrototype);

        // self.buttons.confirm.hide();
        // self.setContent('Coś poszlo nie tak, spróbuj jeszcze raz');
    });

    
            
        });
    });
});

const
    toast = document.querySelector(".toast-alert"),
    closeIcon = document.querySelector(".close"),
    progress = document.querySelector(".progress");

let timer1, timer2;

if ($('.toast-alert').length) {
    setTimeout(() => {
        $('.toast-alert').removeClass('active').remove();
    }, 5000);
}


$('#footer').on('click', '.close', function() {
    $('.toast-alert').removeClass("active").remove;

    setTimeout(() => {
        progress.classList.remove("active").remove();
    }, 300);

    clearTimeout(timer1);
    clearTimeout(timer2);
});


$(document).ready(function () {
    AOS.init();

    $('.owl-carousel').owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        loop: true,
        dots: true,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });
});

$(window).on('scroll', e => {
    $('.section-anchor').each(function () {
        if ($(this).offset().top - 200 < $(window).scrollTop()) {
            let id = '#' + $(this).attr('id').toLowerCase()
            $('.nav-link').removeClass('active')
            $('.nav-link[href="' + id + '"]').addClass('active')
        }
    });
})